export const sizeBanner = {
  // horizontal2: {
  //   "sizes": [[300, 250], [300, 600], [970, 250], [950, 200], [728, 90], [950, 300], [970, 300], [950, 250], [970, 200], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1280, 0],
  //       "msizes": [[970, 250], [950, 200], [950, 300], [970, 300], [950, 250], [970, 200]]
  //     },
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[728, 90]]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes": [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
  // horizontal3: {
  //   "sizes": [[300, 250], [300, 600], [970, 250], [950, 200], [728, 90], [950, 300], [970, 300], [950, 250], [970, 200], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1280, 0],
  //       "msizes": [[970, 250], [950, 200], [950, 300], [970, 300], [950, 250], [970, 200]]
  //     },
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[728, 90]]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes": [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
  // horizontal4: {
  //   "sizes": [[300, 250], [300, 600], [970, 250], [950, 200], [728, 90], [950, 300], [970, 300], [950, 250], [970, 200], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1280, 0],
  //       "msizes": [[970, 250], [950, 200], [950, 300], [970, 300], [950, 250], [970, 200]]
  //     },
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[728, 90]]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes": [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
  // caja2: {
  //   "sizes": [[300, 250], [300, 600], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[300, 250], "fluid"]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes": [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
  caja3: {
    "sizes": [[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[300, 250],[300, 600]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  // caja4: {
  //   "sizes": [[300, 250], [300, 600], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[300, 600]]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes": [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
  caja5: {
    "sizes": [[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[300, 600]]
      },
      {
        "viewport": [0, 0],
        "msizes": [[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  // caja7: {
  //   "sizes": [[300, 250], [300, 600], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[300, 600]]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes":  [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
  caja8: {
    "sizes": [[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[300, 250], [300,600]]
      },
      {
        "viewport": [0, 0],
        "msizes":  [[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja9: {
    "sizes": [[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[300, 250], [300,600]]
      },
      {
        "viewport": [0, 0],
        "msizes":  [[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  caja10: {
    "sizes": [[300, 250], [300, 600], "fluid"],
    "mapping": [
      {
        "viewport": [1024, 0],
        "msizes": [[300, 250], [300,600]]
      },
      {
        "viewport": [0, 0],
        "msizes":  [[300, 250], [300, 600], "fluid"]
      }
    ],
    "active": true
  },
  // caja11: {
  //   "sizes": [[300, 250], [300, 600], "fluid"],
  //   "mapping": [
  //     {
  //       "viewport": [1024, 0],
  //       "msizes": [[300, 250], [300,600]]
  //     },
  //     {
  //       "viewport": [0, 0],
  //       "msizes":  [[300, 250], [300, 600], "fluid"]
  //     }
  //   ],
  //   "active": true
  // },
};
