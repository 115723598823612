import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
import { cardMixin, innerMiddleMixin } from "../mixin";

export const ParentGrid = styled.div`
  gap: 20px;
  margin: auto;
  width: max-content;
  grid-template-rows: inherit;
  display: grid;
  grid-template-columns: repeat(4, 314px);
  div {
    &:not(:last-child)::after {
      bottom: -15px !important;
    }
  }
  .div1 {
    grid-area: 1 / 1 / 5 / 3;
  }
  .div2 {
    grid-area: 1 / 3 / 5 / 4;
    height: inherit;
    right: 0;
  }
  .div3 {
    grid-area: 1 / 4 / 2 / 5;
  }
  .div4 {
    grid-area: 2 / 4 / 3 / 5;
  }
  .div5 {
    grid-area: 3 / 4 / 4 / 5;
  }
  .div6 {
    grid-area: 4 / 4 / 5 / 5;
  }
  .div3,
  .div4,
  .div5,
  .div6 {
    ${innerMiddleMixin}
    &:before {
      background-color: white !important;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          background-color: ${ColorDark.greySeparator}!important;
        }
      }
    }
  }

  /*Estilos para Skeleton*/

  .div1.visual-skeleton {
    height: 649px;
    ${breakpoints.phone} {
      height: auto;
    }
  }
  .div3.normal-skeleton,
  .div4.normal-skeleton,
  .div5.normal-skeleton,
  .div6.normal-skeleton {
    height: 140px;
    overflow: hidden;
    padding-top: 0 !important;
    flex-direction: row-reverse !important;
    ${breakpoints.phone} {
      justify-content: space-between;
      height: 150px;
    }
    .mt {
      margin-top: 15px !important;
      width: 42%;
      margin-right: 16px;
      ${breakpoints.phone} {
        width: 60%;
      }
    }
    .picture {
      width: 135px;
      height: 135px;
      ${breakpoints.phone} {
        width: 125px;
        height: 125px;
        min-height: unset;
        aspect-ratio: unset;
      }
    }
  }

  ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 314px);
    .div1 {
      grid-area: 1 / 1 / 5 / 3;
    }
    .div2 {
      grid-area: 1 / 3 / 5 / 4;
      height: inherit;
    }
    .div3 {
      grid-area: 5 / 1 / 6 / 2;
    }
    .div4 {
      grid-area: 5 / 2 / 6 / 3;
    }
    .div5 {
      grid-area: 5 / 3 / 6 / 4;
    }
    .div6 {
      display: none;
    }
    .banner-skeleton {
      position: relative !important;
      height: 649px;
      top: 0;
    }
  }
  .banner {
    margin-bottom: inherit;
    justify-content: center;
  }
  ${breakpoints.phone} {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    .banner {
      min-height: 300px;
      align-items: center;
    }

    .div1 {
      order: 1;
    }
    .div2 {
      order: 6;
    }
    .div3 {
      order: 2;
    }
    .div4 {
      order: 3;
    }
    .div5 {
      order: 4;
    }
    .div6 {
      order: 5;
    }
  }
`;
export const Card = styled.div`
  ${cardMixin}
  height: fit-content;
`;

export const InnerCardM = styled.div`
  ${innerMiddleMixin};
`;
