import styled from "styled-components";
import { Color, backBanner, breakpoints } from "@/shared/Constants";
export const BannerSmallContent = styled.div`
  width:100%;
  height: 300px;
  overflow: hidden;
  /* ${backBanner} */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  min-height: 300px;
  @media (max-width: 1023px) {
    height: auto;
  }
`;

export const BannerSticky = styled.div` 
    width: 300px;
    /* height: 600px; */
    height: ${({ heightBanner }) => heightBanner ? heightBanner : "600px"};
    overflow: hidden;
    /* margin-bottom: 1rem; */
    /* ${backBanner} */
    position: sticky!important;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
      ${breakpoints.phone} {
        margin-bottom:12px;
        width:100%;
        margin:12px auto;
      }
`;

export const BannerFlotante4 = styled.div`
  position: fixed!important;
  top: 67px;
  z-index: 4;
  left: 50%;
  transform: translate(-50%, 0);
  button {
    align-items: center;
    background: #fff;
    border: 1px solid;
    border-radius: 24px;
    justify-content: center;
    position: absolute;
    right: -7px;
    bottom: -10px;
    width: 24px;
    height: 24px;
    justify-content: center;
    display: none;
  }
  &.b-visible {
    display: block;
  }
  &.b-hide {
    display: none;
  }
`;

export const BannerFlotante1 = styled.div`
  height: 0px;
`;

export const BannerFlotante2 = styled.div`
  position: fixed!important;
  bottom: 0;
  z-index: 4;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  button {
    align-items: center;
    background: #fff;
    border: 1px solid;
    border-radius: 24px;
    justify-content: center;
    position: absolute;
    right: -7px;
    top: -10px;
    width: 24px;
    height: 24px;
    justify-content: center;
    display: none;
  }
`;

export const BannerInread = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin: 15px 0;
  /* ${backBanner} */
  min-height:240px;
  position: relative;
  ${breakpoints.mobileFirst}{
    min-height:470px;
  }
`;

export const BannerCajaGris = styled.div`
  /* ${backBanner}; */
  align-items: center;
  display:flex;
  min-height: ${({ heightBanner }) => heightBanner ? heightBanner : "300px"};
  margin-bottom:${({ heightBanner }) => heightBanner == "0px" ? heightBanner : "20px"};
  position:relative;
  justify-content: center;
  overflow:hidden;
  iframe{
    max-height: 600px;
  }
  &:after {
      content: "PUBLICIDAD";
      /* color: #ddd; */
      font-size: 7px;
      position: absolute;
      bottom: 3px;
      right: 11px;
  }
  &.sticky {
    justify-content: inherit;
    align-items: inherit;
    ${breakpoints.phone} {
      margin-bottom:12px;
    }
    overflow: hidden;
    position: sticky !important;
    top: 60px;
  
  }
`


export const BannerCollapse = styled.div`
  min-height: 0px;
  margin-bottom: 5px;
  text-align: center;
  iframe{
    min-height: 0px;
    margin: 0px !important;
  }
  &.sticky {
    justify-content: inherit;
    align-items: inherit;
    ${breakpoints.phone} {
      margin-bottom:12px;
    }
    overflow: hidden;
    position: sticky !important;
    top: 60px;
  
  }
`

export const ContentSticky = styled.div`
  position: relative;
  margin-bottom: 20px;
  height: 800px;
  min-width: 300px;
  /* ${backBanner}; */
`

export const BannerCajaGrisHorizontal = styled.div`
  ${backBanner};
  align-items: center;
  display:flex;
  width:100%;
  min-height: ${({ heightBanner }) => heightBanner ? heightBanner : "300px"};
  margin-bottom:20px;
  position:relative;
  justify-content: center;
  overflow:hidden;
  &:after {
      content: "PUBLICIDAD";
      color: #ddd;
      font-size: 7px;
      position: absolute;
      bottom: 3px;
      right: 11px;
  }
`
