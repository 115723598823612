import { BannerCajaGris } from "@/shared/ContentBanner";
import { ParentGrid } from "./style";
import { GenericNormalSkeleton, GenericVisualSkeleton } from "@/shared/Skeleton";

const Skeleton = () => {
  return (
    <ParentGrid className={`Container1Piso4 module-grid`}>
      {[...Array(5)].map((_, index) => (
        <>
          {index === 0 ? (
            <GenericVisualSkeleton classType={`div${index + 1}`} key={index} />
          ) : <>
            {index === 1 && (
              <BannerCajaGris heightBanner="600px" className={`banner sticky div${index + 1} banner-skeleton`} />
            )}
            <GenericNormalSkeleton classType={`div${index + 2}`} key={index} />
          </>}
        </>
      ))}
    </ParentGrid>
  );
};

export default Skeleton;
