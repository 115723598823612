import { useState, useEffect } from "react";
import { ContetBanner, DummyBanner } from "../style";
import Script from "next/script";


const BannerLazy = ({ isPreview, slotId, adsPath , mapping }) => {
  
  const [isClient, setIsClient] = useState(false);
  const adsPathModificated = adsPath.includes("arq") ? adsPath : "clarin/"+adsPath;
  const sizeMapping = mapping.mapping
    ? mapping.mapping
      .map((entry) => {
        const viewportString = JSON.stringify(entry.viewport);
        const msizesString = JSON.stringify(entry.msizes);
        return `.addSize(${viewportString}, ${msizesString})`;
      })
      .join('\n')
    : "";
  const sizeString = JSON.stringify(mapping.sizes);
  const defaultLazyLoadConfig= {
                                  fetchMarginPercent: 50,
                                  renderMarginPercent: 25,
                                  mobileScaling: 0.5
                              };
  const lazyLoadConfig = mapping.lazy ? JSON.stringify(mapping.lazy) : JSON.stringify(defaultLazyLoadConfig);

  useEffect(() => {
    setIsClient(true);
    
  }, []);

  if (isPreview) {
    return (
      <DummyBanner>
        <p className={`banner-reserved-space`}>Banner {slotId}</p>
      </DummyBanner>
    );
  }

  return (
    <>
      {isClient ?
          <ContetBanner className="lazyBanner" id={`div-gpt-ad-${slotId}`}>
            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                  __html: `
                    window.googletag = window.googletag || {cmd: []};
                    googletag.cmd.push(function() {
                      
                      let addSize = (googletag.sizeMapping()
                      ${sizeMapping}
                      .build());
                      
                      let targetSlot = googletag.defineSlot('/242767168/${adsPathModificated}/${slotId}', ${sizeString}, 'div-gpt-ad-${slotId}')
                        .addService(googletag.pubads())
                        .defineSizeMapping(addSize);
                      googletag.pubads().enableLazyLoad(${lazyLoadConfig});
                      googletag.enableServices();
                      googletag.display('div-gpt-ad-${slotId}');
                      googletag.pubads().refresh([targetSlot]);
                      googletag.pubads().addEventListener('slotRenderEnded', function(event) {
                        event.slot.getSlotElementId()
                      });
                      googletag.enableServices();
                    }); 
                    
                `,
              }}
            />
          </ContetBanner>
        :
          <></>
      }
    </>
  )
};

export default BannerLazy;
